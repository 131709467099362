import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import AnnouncementDetailsPresenter from "./AnnouncementDetailsPresenter";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import Queue from "nq";

class AnnouncementDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new AnnouncementDetailsPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      announcement: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getAnnouncementId() {
    const params = this.getParams();
    return params.id;
  }

  setAnnouncement(announcement) {
    this.setState({ announcement });
  }

  getAnnouncement() {
    return this.state.announcement || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  render() {
    const announcement = this.state.announcement;
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            {announcement && (
              <Card className="container col-xxl-12 ">
                <div className="px-1 py-1 my-1 text-center">
                  <h1 className="display-6 fw-bold">{announcement.title}</h1>
                  <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">{announcement.description}</p>
                    <iframe
                      src={Queue.File.getFile(announcement?.attachment)}
                      className="w-100"
                      style={{ height: "100vh" }}
                    />
                  </div>
                </div>
              </Card>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withContext(AnnouncementDetailsPage);
