import answersScore from "../../answersScore";

class FormAnswersPresenter {
  constructor(view, findObjectUseCase, deleteObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  onClickAdd() {
    this.view.showForms();
  }

  componentDidMount() {
    // this.init();
    this.where = {};
    this.getAnswers();
    this.getForms();
    this.getBrands();
    // this.getStores();
  }

  init() {
    this.where = {};
    this.current = 1;
  }

  getForms() {
    const query = {};
    this.findObjectUseCase.execute("forms", query).then((forms) => {
      this.view.setForms(forms);
    });
  }

  getBrands() {
    const collection = "brands";
    this.findObjectUseCase
      .execute(collection)
      .then((object) => {
        this.view.setBrands(object);
      })
      .catch((error) => {});
  }

  // getStores() {
  //   const collection = "stores";
  //   this.findObjectUseCase
  //     .execute(collection)
  //     .then((object) => {
  //       this.view.setStore(object);
  //     })
  //     .catch((error) => {});
  // }

  onSelectForm(form) {
    this.view.navigateTo("/form/answer/" + form.id);
  }

  getAnswers() {
    console.log("hahaha", this.where);
    const query = {
      include: ["answers.question", "form", "store"],
      // sort: {createdAt: -1},
      // limit: 100,
      where: this.where,
    };

    this.view.showProgress();
    this.findObjectUseCase
      .execute("form_answers", query)
      .then((answers) => {
        console.log("haha ans", answers);
        answers.forEach((answer) => {
          const result = answersScore(answer.answers);
          answer.average = (result.score / result.total) * 100;
        });
        this.view.setAnswers(
          answers.sort((b, a) => new Date(a.createdAt) - new Date(b.createdAt))
        );
        this.view.hideProgress();
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onClickAnswer(answer) {
    this.view.navigateTo("/form/answer/" + answer.form.id + "/" + answer.id);
  }

  onDeleteAnswer(answer) {
    const collection = "form_answers";
    this.view
      .showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      })
      .then(() => {
        this.view.setAnswers([]);
        this.deleteObjectUseCase
          .execute(collection, answer.id)
          .then(() => {
            this.getAnswers();
          })
          .catch((error) => {
            this.getAnswers();
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onChangeDate(where) {
    this.init();
    this.where.createdAt = where.createdAt;
    this.getAnswers();
  }

  onChangeStore(value) {
    // this.where.store = { id: value.id };
    this.init();
    console.log("store", value);
    this.view.setStore(value.id);
    this.where.store = value;
    console.log("where store", this.where.store);

    this.getAnswers();
  }

  onChangeBrand(value) {
    this.init();
    const prevBrand = this.view.getBrand();
    this.view.setBrand(value.id);
    if (prevBrand !== value.id) {
      delete this.where.store;
    }
    this.where.brand = value;

    console.log(this.where.brand);
    this.getAnswers();
  }

  loadMore() {
    this.current++;
    this.getAnswers();
  }
}

export default FormAnswersPresenter;
