import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";
import withContext from "../../withContext";
import AnnouncementPresenter from "./AnnouncementPresenter";

import { InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";
import { Link } from "react-router-dom";
import { deleteObjectUseCase, findObjectUseCase } from "../../usecases/object";
import DateRange from "../../components/DateRange";

class AnnouncementPage extends BasePage {
  constructor() {
    super();
    this.presenter = new AnnouncementPresenter(this, findObjectUseCase(), deleteObjectUseCase());
    this.state = {
      announcements: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setAnnouncements(announcements) {
    this.setState({
      announcements,
    });
  }

  onClickAnnouncement(announcement) {
    this.presenter.onClickAnnouncement(announcement);
  }

  getAnnouncementId() {
    const params = this.getParams();
    return params.id;
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    // this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  onDeleteAnnouncement(event, announcement) {
    event.stopPropagation();
    this.presenter.onDeleteAnnouncement(announcement);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const announcements = this.state.announcements;
    const roles = this.getCurrentRoles();
    // const user = this.getCurrentUser().department ? "" : this.getCurrentUser().department.id;

    // console.log("Login User",user)
    // console.log("Announcements",announcements)

    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Announcement.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <form className="mt-3">
              <div className="d-flex justify-content-end">
                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>
            </form>
            <Card className="mb-3 mt-3">
              <h6 className="border-bottom pb-2 mb-0">
                <i className="bi bi-megaphone me-1"></i> Announcement
              </h6>
              <Scroll
                hasMore={announcements.length < count}
                loadMore={this.loadMore.bind(this)}
              >
                {announcements.map((announcement, id) => (
                  <div
                    key={id}
                    onClick={this.onClickAnnouncement.bind(this, announcement)}
                    className="mb-1 cursor-pointer"
                  >
                    <div className="d-flex text-muted pt-3">
                      <small className="d-block border-end border-1">
                        {dateFormat(announcement.createdAt)}
                      </small>
                      <div className="mx-3 pb-3 mb-0 small lh-sm w-100">
                        <strong className="d-block">
                          {announcement.title}
                        </strong>
                        <small className="d-block">
                          {announcement.description}
                        </small>
                      </div>
                      <div
                        className={roles?.admin ? "" : "d-none"}
                        onClick={(event) => this.onDeleteAnnouncement(event, announcement)}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                ))}
                {progress && <Progress className="fs-sm">Loading ...</Progress>}
                {!progress && announcements.length === 0 && (
                  <NotFoundPage message="No results found" />
                )}
              </Scroll>
            </Card>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0">
          <Link to="/announcement/create">
          <div
                        className={roles?.admin ? "" : "d-none"}
                      >
            <button className="btn btn-primary rounded-circle m-4 shadow">
              <i className="bi bi-plus fs-4"></i>
            </button>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default withContext(AnnouncementPage);
