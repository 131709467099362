import React from "react";
import ReactDOM from "react-dom";

import BasePage from "../../base/BasePage";
import FormAnswerPresenter from "./FormAnswerPresenter";
import NavBar from "../../components/NavBar";
import InputFactory from "../../components/InputFactory";
import ExportFormAnswer from "../../components/ExportFormAnswer";
import printComponent from "../../printComponent";

import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
  deleteObjectUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import { Progress } from "nq-component";
import InputAnswer from "../../components/InputAnswer";
import Question from "../../components/Question";
import InputImageAnswer from "../../components/InputImageAnswer";
import InputString from "../../components/InputString";
import InputSignature from "../../components/InputSignature";

class FormAnswerPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      form: null,
      answer: null,
      validate: false,
      submit: false,
      brands: null,
      brand: null,
      file: [],
    };
    this.presenter = new FormAnswerPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase(),
      deleteObjectUseCase()
    );
    this.pdfRef = React.createRef();
    this.signRef = React.createRef();
    this.adminSignRef = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getFormId() {
    return this.props.params.formId;
  }

  getAnswerId() {
    return this.props.params.answerId;
  }

  setForm(form) {
    form.categories = form.categories.sort((a, b) =>
      a.name < b.name ? -1 : 1
    );
    this.setState({ form });
  }

  setAnswer(answer) {
    this.setState({ answer });
  }

  getAnswer() {
    return this.state.answer;
  }

  formSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  backCLick() {
    this.presenter.backClick();
  }

  HandleExport(e) {
    e.stopPropagation();

    this.presenter.onClickExport();
  }

  exportPDF() {
    this.showProgressDialog();
    printComponent(this.pdfRef.current, "audit").then(() => {
      this.hideProgressDialog();
    });
  }

  getValidate() {
    return this.state.validate;
  }

  setValidate(value) {
    this.setState({ validate: value });
  }

  setValidateFalse() {
    this.setState({ validate: false });
  }

  setSubmit(value) {
    this.setState({ submit: value });
  }

  getFile() {
    return this.state.file;
  }

  getBrand() {
    return this.state.brand;
  }

  setBrand(value) {
    this.setState({ brand: value });
  }

  setBrands(value) {
    this.setState({ brands: value });
  }

  onChangeStore(value) {
    this.presenter.onChangeStore(value);
  }

  onChangeBrand(value) {
    this.presenter.onChangeBrand(value);
  }

  render() {
    const form = this.state.form;
    const answer = this.state.answer;
    const validate = this.state.validate;
    const submit = this.state.submit;
    const file = this.state.file;
    const brand = this.state.brand;
    const brands = this.state.brands;

    if (form === null || answer === null) {
      return <Progress />;
    }
    let index = -1;

    return (
      <>
        <div className="d-none">
          <div ref={this.pdfRef}>
            {answer.id && <ExportFormAnswer answers={answer} forms={form} />}
          </div>
        </div>
        <NavBar className="shadow-sm" />
        <div className="container overflow-auto">
          <div className="p-3 px-lg-5 py-lg-4">
            <div className="text-end">
              {answer?.id && (
                <button
                  className="btn btn-primary btn-sm text-white"
                  onClick={this.HandleExport.bind(this)}
                >
                  EXPORT
                </button>
              )}
            </div>
            <h2 className="text-capitalize mt-3">{form.name}</h2>
            <p>{form.subtitle}</p>
            <form onSubmit={this.formSubmit.bind(this)}>
              <div className="d-flex">
                <div className="input-group w-auto">
                  <i className="input-group-text bi bi-shop"></i>
                  <InputFactory
                    field="brand"
                    object={answer}
                    type="Pointer"
                    target="brands"
                    onChange={this.onChangeBrand.bind(this)}
                  />
                </div>

                <div className="input-group w-auto">
                  {brands?.map((_brand) => {
                    if (_brand?.id !== brand) {
                      return;
                    }
                    return (
                      <>
                        <i className="input-group-text bi bi-shop" />
                        <InputFactory
                          label="select store"
                          field="store"
                          object={answer}
                          type="Pointer"
                          target="stores"
                          where={{ brand: { id: _brand?.id } }}
                          onChange={this.onChangeStore.bind(this)}
                        />
                      </>
                    );
                  })}
                  {!brand && answer?.id && (
                    <>
                      <i className="input-group-text bi bi-shop" />
                      <InputFactory
                        field="store"
                        object={answer}
                        type="Pointer"
                        target="stores"
                      />
                    </>
                  )}
                </div>
              </div>

              <Question
                categories={form.categories}
                answer={answer}
                submit={submit}
                setSubmit={this.setSubmit.bind(this)}
                validate={validate}
                file={file}
                setValidate={this.setValidateFalse.bind(this)}
              />

              <div>
                <button type="submit" className="btn btn-primary fs-sm me-3">
                  <i className="bi bi-file-earmark-check me-2"></i>SAVE
                </button>
                <button
                  type="button"
                  className="btn btn-light fs-sm"
                  onClick={this.backCLick.bind(this)}
                >
                  GO BACK
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(FormAnswerPage);
