class TrainingExamViewPresenter {
    constructor(
        view,
        findObjectUseCase,
        saveObjectUseCase,
        updateObjectUseCase,
        saveFileUseCase
    ) {
        this.view = view;
        this.findObjectUseCase = findObjectUseCase;
        this.saveObjectUseCase = saveObjectUseCase;
        this.updateObjectUseCase = updateObjectUseCase;
        this.saveFileUseCase = saveFileUseCase;
        this.learn = null;
    }

    componentDidMount() {
        this.view.showProgress();
        Promise.resolve()
            .then(() => this.getTraining())
            .then(() => this.view.hideProgress())
            .catch((error) => {
                this.view.hideProgress();
                this.view.showError(error);
            });
    }

    getTraining() {
        this.learn = this.view.getTraining();
        this.view.setTraining(this.learn);
        console.log("qqq");
        if (!this.learn) {
            const id = this.view.getTrainingId();
            const query = {
                where: { id: id },
                include: ["exams_categories.exams_questions","exams_answers","categories.questions.answers"],
            };
            return this.findObjectUseCase.execute("exams", query).then(([learn]) => {
                this.learn = learn;
                this.view.setTraining(this.learn);
            });
        }
    }

    onClickBack() {
        this.view.navigateBack();
    }
}

export default TrainingExamViewPresenter;
