import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import TrainingDetailsPresenter from "./TrainingDetailsPresenter";
import { Link } from "react-router-dom";
import dateFormat from "../../dateFormat";

class TrainingDetailsPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new TrainingDetailsPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      learn: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getTrainingId() {
    const params = this.getParams();
    return params.id;
  }

  setTraining(learn) {
    this.setState({ learn });
  }

  getTraining() {
    return this.state.learn || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  convertToEmbedUrl(youtubeUrl) {
    // Regular expression to extract the video ID and playlist ID from the YouTube URL
    const regex =
      /(?:\/watch\?v=|\/embed\/|youtu\.be\/)([a-zA-Z0-9_-]+)(?:&list=([a-zA-Z0-9_-]+))?/;

    // Search for the video ID and playlist ID in the input URL
    const match = youtubeUrl.match(regex);

    if (match) {
      const videoId = match[1];
      const playlistId = match[2];

      // Construct the embed URL with the extracted video ID
      let embedUrl = `https://www.youtube.com/embed/${videoId}`;

      // If there is a playlist ID, append it to the embed URL
      if (playlistId) {
        embedUrl += `?list=${playlistId}`;
      }

      return embedUrl;
    } else {
      // If the URL doesn't match the expected pattern, return null
      return null;
    }
  }

  sortVideos(videos) {
    return videos?.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }

  render() {
    const learn = this.state.learn;
    const videos = this.sortVideos(learn?.videos);
    console.log(videos);
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="nav-scroller">
          <nav className="nav nav-tabs">
            <Link to="/training/videos">
              <button className="text-nowrap nav-link active" href="#">
                <i className="bi bi-arrow-left-circle"></i> Back
              </button>
            </Link>
          </nav>
        </div>
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <div
              id="vid-content"
              className="container-fluid d-flex flex-column justify-content-center"
            >
              <div className="row justify-content-center">
                <h2 className="fw-bold text-center mb-3">{learn?.title}</h2>
                {videos?.map((item, index) => {
                  return (
                    <div className="col-sm-6" key={index}>
                      <Card>
                        <div className="embed-responsive embed-responsive-16by9">
                          <iframe
                            width="100%"
                            height="315"
                            src={this.convertToEmbedUrl(item.url)}
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen
                          ></iframe>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="p-1 align-self-center">
                            <h6 className="m-0 p-0">Upload Date</h6>
                            <span className="float-end">
                              <small>{dateFormat(item.createdAt)}</small>
                            </span>
                          </div>
                        </div>

                        <div className="container m-0 p-0 pt-4">
                          <h5 className="ms-2 me-2">
                            <strong>{item?.name}</strong>
                          </h5>
                        </div>
                      </Card>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withContext(TrainingDetailsPage);
