import React, { Component } from "react";

class TrainingVideoFormPresenter {
  constructor(view, saveObjectUseCase, findObjectUseCase, updateObjectUseCase) {
    this.view = view;
    this.saveObjectUseCase = saveObjectUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
  }

  submit() {
    const object = this.view.getObject();
    const user = this.view.getCurrentUser();

    // const department = 'role:' + object.departments.name;
    const admin = "role:" + "admin";

    object.acl = {
      read: [user.id, admin],
      write: [user.id, admin],
    };

    this.view.showProgress();
    this.saveObjectUseCase
      .execute("training_videos", object)
      .then(() => {
        this.view.hideProgress();
        this.view.navigateTo("/training/video-links");
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}
export default TrainingVideoFormPresenter;
