import storeRanking from "../../storeRanking";

class StoreRankPresenter {
  constructor(view, findObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
  }

  onClickAdd() {
    this.view.showForms();
  }

  componentDidMount() {
    this.where = {};
    this.getAnswers();
    this.getBrands();
  }

  init() {
    this.where = {};
  }

  onSelectForm(form) {
    this.view.navigateTo("/form/answer/" + form.id);
  }

  getAnswers() {
    // if (!this.where.brand || !this.where.createdAt) return;

    const query = {
      include: ["form", "store", "answers.question", "brand"],
      where: this.where,
    };
    this.view.showProgress();
    this.findObjectUseCase.execute("form_answers", query).then((answers) => {
      console.log("rank", answers);
      const ranks = storeRanking(answers);

      console.log("rank", ranks);
      this.view.setAnswers(ranks);
      this.view.hideProgress();
    });
  }

  onClickAnswer(answer) {
    this.view.navigateTo("/form/answer/" + answer.form.id + "/" + answer.id);
  }

  onChangeDate(where) {
    this.init();
    this.where.createdAt = where.createdAt;
    console.log(this.where.createdAt);
    this.getAnswers();
  }

  onClickExport() {
    this.view.exportPDF();
  }

  //   onChangeBrand(value) {
  //     this.init();
  //     this.where.brand = value;
  //     console.log(this.where.brand);
  //     this.getAnswers();
  //   }

  onChangeBrand(value) {
    const prevBrand = this.view.getBrand();
    this.view.setBrand(value.id);
    if (prevBrand !== value.id) {
      delete this.where.store;
    }
    this.where.brand = value;
    console.log(this.where.brand);
    this.getAnswers();
  }

  getBrands() {
    const collection = "brands";
    this.findObjectUseCase
      .execute(collection)
      .then((object) => {
        this.view.setBrands(object);
      })
      .catch((error) => {});
  }
}

export default StoreRankPresenter;
