class TrainingAdminPresenter {
  constructor(view, findObjectUseCase, deleteObjectUseCase, saveObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
    this.getExpiredLinks();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      include: ["all"],
      sort: { createdAt: -1 },
    };

    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("training_links", query)
      .then(({ count, objects }) => {
        // this.documents = this.documents.concat(objects);
        this.view.setCount(count);
        this.view.setTrain(objects);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getCurrentDateString() {
    const isoString = new Date().toISOString().slice(0, 11);
    const time = new Date().toTimeString().slice(0, 8);
    const milliseconds = ".000Z";
    return `${isoString}${time}${milliseconds}`;
  }

  getActiveLinks(id) {
    const query = {
      count: true,
      limit: 5,
      where: {
        video: { id: id },
        date_exp: { $gte: this.getCurrentDateString() },
      },
      include: ["all"],
      sort: { createdAt: -1 },
    };

    return this.findObjectUseCase
      .execute("links", query)
      .then(({ objects }) => {
        this.view.setActiveLinks(objects);
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }

  getExpiredLinks() {
    const query = {
      count: true,
      where: {
        date_exp: { $lte: this.getCurrentDateString() },
      },
      include: ["all"],
      sort: { createdAt: -1 },
    };

    return this.findObjectUseCase
      .execute("links", query)
      .then(({ objects }) => {
        this.view.setState({ expiredLinks: objects });
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }

  async clearExpiredLinks() {
    try {
      this.view.setState({ clearProgress: true });
      const expiredLinks = this.view.state.expiredLinks;
      for (const link of expiredLinks) {
        await this.deleteObjectUseCase.execute("links", link.id);
      }
      this.view.setState({ clearProgress: false });
      this.getExpiredLinks();
    } catch (error) {
      this.view.showError(error);
    }
  }

  onClickTrain(learn) {
    this.view.navigateTo("/training/edit", learn);
  }

  onSubmitSearch(word) {
    this.where = {
      title: { $regex: word, $options: "i" },
    };
    this.documents = [];
    this.current = 1;
    this.getData();
  }

  onChangeDate(where) {
    // this.init();
    this.where.createdAt = where.createdAt;
    this.getData();
  }

  onDeleteLink(link) {
    const collection = "links";
    this.view
      .showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      })
      .then(() => {
        // this.view.setTrain([]);
        this.deleteObjectUseCase
          .execute(collection, link.id)
          .then(() => {
            this.getActiveLinks();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onDeleteTrain(learns) {
    const collection = "training_links";
    this.view
      .showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      })
      .then(() => {
        // this.view.setTrain([]);
        this.deleteObjectUseCase
          .execute(collection, learns.id)
          .then(() => {
            this.getData();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onGenerateLink(id, timeExpiration) {
    const collection = "links";
    const object = {
      video: { id: id },
      date_exp: timeExpiration,
    };

    const user = this.view.getCurrentUser();
    const admin = "role:" + "admin";

    object.acl = {
      read: [user.id, admin, "*"],
      write: [user.id, admin],
    };

    if (!this.view.validateTimeExpiration(this.view.state.timeExpiration))
      return;

    this.view.showProgress();
    this.saveObjectUseCase
      .execute(collection, object)
      .then((data) => {
        this.view.setState({ generatedLink: data.id });
        this.view.hideProgress();
        this.getActiveLinks(id);
      })
      .catch((error) => {
        this.view.showError(error);
        this.view.hideProgress();
      });
  }
}

export default TrainingAdminPresenter;
