class TrainingVideosPresenter {
  constructor(view, findObjectUseCase, deleteObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
    this.limit = 10;
    this.current = 1;
    this.where = {};
    this.documents = [];
    this.progress = false;
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      limit: this.limit,
      skip,
      where: this.where,
      include: ["all"],
      sort: { createdAt: -1 },
    };

    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("training_videos", query)
      .then(({ count, objects }) => {
        // this.documents = this.documents.concat(objects);
        this.view.setCount(count);
        this.view.setTrain(objects);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onClickTrain(learn) {
    this.view.navigateTo("/training/videos/edit", learn);
  }

  onSubmitSearch(word) {
    this.where = {
      title: { $regex: word, $options: "i" },
    };
    this.documents = [];
    this.current = 1;
    this.getData();
  }

  onChangeDate(where) {
    // this.init();
    this.where.createdAt = where.createdAt;
    this.getData();
  }

  onDeleteTrain(learns) {
    const collection = "training_videos";
    this.view
      .showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      })
      .then(() => {
        // this.view.setTrain([]);
        this.deleteObjectUseCase
          .execute(collection, learns.id)
          .then(() => {
            this.getData();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default TrainingVideosPresenter;
