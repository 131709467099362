import React, { Component } from 'react';

class AnnouncementFormPresenter {

    constructor(view, saveObjectUseCase, findObjectUseCase, updateObjectUseCase){
        this.view = view;
        this.saveObjectUseCase = saveObjectUseCase;
        this.findObjectUseCase = findObjectUseCase;
        this.updateObjectUseCase = updateObjectUseCase;
    }

    submit() {
        const object = this.view.getObject();
        const user = this.view.getCurrentUser();

        const department = 'role:' + object.departments.name;

        const admin = 'role:' + 'admin';

        object.acl = {
            read: [user.id, department, admin],
            write: [user.id, department, admin]
        };
        
        object.attachment = object.attachment[0]

        this.view.showProgress();
        this.saveObjectUseCase
            .execute("announcements", object)
            .then(()=> {
                this.view.hideProgress()
                this.view.navigateTo('/announcement')
            })
            .catch((error) => {
                this.view.hideProgress();
                this.view.showError(error);
            });

    }

}

export default AnnouncementFormPresenter;