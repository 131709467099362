import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";

import withContext from "../../withContext";
import TrainingPresenter from "./TrainingPresenter";
import { findObjectUseCase, deleteObjectUseCase } from "../../domain/object";
import { InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";
import DateRange from "../../components/DateRange";
// import { Link } from "react-router-dom";

class TrainingPage extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingPresenter(
      this,
      findObjectUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      learns: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setLearns(learns) {
    this.setState({
      learns,
    });
  }

  onClickLearn(learn) {
    this.presenter.onClickLearn(learn);
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onDeleteTraining(event, learn) {
    event.stopPropagation();
    this.presenter.onDeleteTraining(learn);
  }

  hasAdminRole(userData) {
    return (
      userData.roles && userData.roles.some((role) => role.name === "Admin")
    );
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const learns = this.state.learns;
    const roles = this.getCurrentUser();
    console.log(roles);

    return (
      <>
        <NavBar className="shadow-sm" />

        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Training.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <div className="d-flex justify-content-end w-100">
              <form className="mt-3">
                <div className="d-flex justify-content-end">
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
              </form>
            </div>

            <Scroll
              hasMore={learns.length < count}
              loadMore={this.loadMore.bind(this)}
            >
              <Card className={"mt-3"}>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mt-1">
                  {learns.map((learn, id) => (
                    <div className="col" key={id}>
                      <div
                        className="bg-light rounded h-100 mb-1 cursor-pointer"
                        onClick={this.onClickLearn.bind(this, learn)}
                      >
                        <img
                          className=" card-img-top"
                          src={learn.image}
                          alt="img"
                          width="100%"
                          height="270"
                          loading="lazy"
                        />

                        <div className="card-body">
                          {this.hasAdminRole(roles) ? (
                            <div
                              onClick={(event) =>
                                this.onDeleteTraining(event, learn)
                              }
                            >
                              <i className="bi bi-trash"> - </i>
                              <strong className="card-text">
                                {learn.title}
                              </strong>
                            </div>
                          ) : (
                            <></>
                          )}
                          <p
                            className="card-text text-truncate"
                            style={{ maxWidth: "900px" }}
                          >
                            {learn.description}
                          </p>
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                              <span className="badge bg-primary me-1">
                                Store Department
                              </span>
                            </div>
                            <small className="text-muted">
                              {dateFormat(learn.createdAt)}
                            </small>
                          </div>
                          <div>
                            {learn.category.map((category, id) => (
                              <span className="badge bg-success me-1" key={id}>
                                {category.name}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Card>
              {progress && <Progress className="fs-sm">Loading ...</Progress>}
              {!progress && learns.length === 0 && (
                <NotFoundPage message="No results found" />
              )}
            </Scroll>
          </div>
        </div>
        {/* <div className="position-fixed bottom-0 end-0">
          <Link to="/training/create">
            <div
                className={roles?.admin ? "" : "d-none"}
            >
              <button className="btn btn-primary rounded-circle m-4 shadow">
                <i className="bi bi-plus fs-4"></i>
              </button>
            </div>
          </Link>
        </div> */}
      </>
    );
  }
}

export default withContext(TrainingPage);
