import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import TrainingVideoEditPresenter from "./TrainingVideoEditPresenter";
import { InputString } from "nq-component";
// import { InputDate, InputSelect, InputString } from "nq-component";
// import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";
// import InputFactory from "../../components/InputFactory";
// import { InputImage } from "nq-component";
// import { saveImageUseCase } from "../../usecases/file";

class TrainingVideoEdit extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new TrainingVideoEditPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      video: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getTrainingId() {
    const params = this.getParams();
    return params.id;
  }

  setTraining(video) {
    this.setState({ video });
  }

  getTraining() {
    return this.state.video || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  onSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  navigateBack() {
    this.navigateTo("/training/video-links");
  }

  render() {
    const video = this.state.video;
    console.log(video);
    return (
      <>
        <NavBar className="shadow-sm" />

        {video && (
          <div className="container">
            <div className="h-100">
              <div class="p-3 p-lg-4">
                <h1 className="fw-bold mt-3 text-capitalize">Edit Training</h1>
                <div class="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                  <form onSubmit={this.onSubmit.bind(this)}>
                    <div class="row g-3 mt-3">
                      <div className="col-md-12">
                        <p className="small fw-bold mb-0 ms-1">
                          General Details
                        </p>
                        <hr />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="formFile" class="form-label">
                          Video Name
                        </label>
                        <InputString
                          className="form-control"
                          field="name"
                          object={video}
                          placeholder="Enter Title Training"
                          required
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="formFile" class="form-label">
                          Video URL
                        </label>
                        <InputString
                          className="form-control"
                          field="url"
                          object={video}
                          placeholder="Enter Description"
                          required
                        />
                      </div>

                      <div className="col-md-12">
                        <div className="d-flex gap-4 h-100 align-items-end pb-2">
                          <button
                            // progress={this.state.progress}
                            type="submit"
                            className="btn btn-primary text-white"
                            style={{ width: 120 }}
                          >
                            SAVE
                          </button>
                          <button
                            className="btn text-primary border border-primary"
                            onClick={() => this.navigateBack()}
                            style={{ width: 120 }}
                          >
                            GO BACK
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withContext(TrainingVideoEdit);
