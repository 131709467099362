import React from "react";
import BasePage from "../../base/BasePage";
import StoreRankPresenter from "./StoreRankPresenter";
import NavBar from "../../components/NavBar";
import { dialog } from "nq-component";
import withRouter from "../../withRouter";
import SelectList from "../../components/SelectList";
import { findObjectUseCase } from "../../usecases/object";
import DateRange from "../../components/DateRange";
import printComponent from "../../printComponent";
import ExportStoreRank from "../../components/ExportStoreRank";
import { Progress } from "nq-component";
import InputFactory from "../../components/InputFactory";

class StoreRankPage extends BasePage {
  constructor(props) {
    super(props);
    this.state = { forms: [], answers: [], brands: null, brand: null };
    this.presenter = new StoreRankPresenter(this, findObjectUseCase());
    this.pdfRef = React.createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onChangeForm(value) {
    dialog.close();
    this.presenter.onSelectForm(value);
  }

  setForms(forms) {
    this.setState({ forms });
  }

  showForms() {
    const forms = this.state.forms;
    dialog.fire({
      component: (
        <SelectList
          onClickItem={this.onChangeForm.bind(this)}
          multiple
          label="Select Form"
          options={forms}
        />
      ),
      footer: false,
    });
  }

  onClickAdd() {
    this.presenter.onClickAdd();
  }

  setAnswers(answers) {
    this.setState({ answers });
  }

  onClickAnswer(answer) {
    this.presenter.onClickAnswer(answer);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onClickExport() {
    this.presenter.onClickExport();
  }

  exportPDF() {
    printComponent(this.pdfRef.current, "audit");
  }

  onChangeStore(value) {
    this.presenter.onChangeStore(value);
  }

  onChangeBrand(value) {
    this.presenter.onChangeBrand(value);
  }

  setBrand(value) {
    this.setState({ brand: value });
  }

  getBrand() {
    return this.state.brand;
  }

  computeProductAverage(formData) {
    let totalPercentage = 0;
    let totalForms = 0;

    // Calculate the total percentage and total number of forms
    Object.keys(formData).forEach((f) => {
      const score = formData[f].score;
      const total = formData[f].total;
      const percentage = (score / total) * 100;
      totalPercentage += percentage;
      totalForms++;
    });

    // Calculate the average percentage
    const averagePercentage = totalPercentage / totalForms;
    return averagePercentage;
  }

  render() {
    const answers = this.state.answers;

    // const formKeys = Object.keys(answers.form);

    console.log("store", answers);

    let rank = 0;

    return (
      <>
        <div className="d-none">
          <div ref={this.pdfRef}>
            <ExportStoreRank answers={answers} />
          </div>
        </div>
        <NavBar className="shadow-sm" />

        <div className="container overflow-auto">
          <div className="p-3 px-lg-5 py-lg-4">
            <h2 className="text-capitalize mt-3 m-0">Store Ranking</h2>
            <div className="text-end">
              <button
                className="btn btn-primary btn-sm text-white"
                disabled={answers.length === 0}
                onClick={this.onClickExport.bind(this)}
              >
                EXPORT
              </button>
            </div>
            <div className="d-flex">
              <div className="input-group w-auto">
                <i className="input-group-text bi bi-shop" />
                <InputFactory
                  label="All Brands"
                  field="brand"
                  object={{}}
                  type="Pointer"
                  target="brands"
                  onChange={this.onChangeBrand.bind(this)}
                />
              </div>
            </div>
            <form className="mt-3">
              <div className="d-flex justify-content-end">
                <DateRange
                  onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>
            </form>
            {this.state.progress && <Progress />}
            {!this.state.brand ? (
              <div className="d-flex flex-column align-items-center justify-content-center text-center mt-5">
                <img width={200} src="/assets/images/choosefirst.png"></img>
                <h3>To proceed, please select a brand and date range</h3>
              </div>
            ) : (
              answers.map((answer, index) => {
                const previous = answers[index - 1];
                const {
                  "Product Quality Audit (1st)": product1,
                  "Product Quality Audit (2nd)": product2,
                  ...form
                } = answer.form;

                const myObj = answer.form;

                for (let prop in myObj) {
                  if (myObj.hasOwnProperty(prop)) {
                  }
                }

                const product1Average =
                  ((product1 && product1.score / product1.total) || 0) * 100;
                const product2Average =
                  ((product2 && product2.score / product2.total) || 0) * 100;
                const outletAverage =
                  (({ ...form } &&
                    { ...form["Store Audit"] }.score /
                      { ...form["Store Audit"] }.total) ||
                    0) * 100;
                const followUpAverage =
                  (({ ...form } &&
                    { ...form["Follow up Audit"] }.score /
                      { ...form["Follow up Audit"] }.total) ||
                    0) * 100;

                //computation for total Product Quality
                let productAverage;
                if (product2Average === 0) {
                  productAverage = product1Average;
                } else if (product1Average === 0) {
                  productAverage = product2Average;
                } else {
                  productAverage = (product1Average + product2Average) / 2;
                }

                //computation for Total Average

                const averages = [
                  outletAverage,
                  followUpAverage,
                  product1Average,
                  product2Average,
                ];

                // Remove any zeros from the array
                const nonZeroAverages = averages.filter((avg) => avg !== 0);

                let TotalAverage;

                switch (nonZeroAverages.length) {
                  case 0:
                    TotalAverage = 0;
                    break;
                  case 1:
                    TotalAverage = nonZeroAverages[0];
                    break;
                  case 2:
                    TotalAverage =
                      (nonZeroAverages[0] + nonZeroAverages[1]) / 2;
                    break;
                  case 3:
                    TotalAverage =
                      (nonZeroAverages[0] +
                        nonZeroAverages[1] +
                        nonZeroAverages[2]) /
                      3;
                    break;
                  case 4:
                    TotalAverage =
                      (nonZeroAverages[0] +
                        nonZeroAverages[1] +
                        nonZeroAverages[2] +
                        nonZeroAverages[3]) /
                      4;
                    break;
                  default:
                    // Use all four averages to calculate total average
                    TotalAverage =
                      (outletAverage +
                        followUpAverage +
                        product1Average +
                        product2Average) /
                      4;
                    break;
                }

                const FinalAverage = TotalAverage;

                //
                if (previous && previous.average === answer.average) {
                  rank--;
                }
                rank++;

                return (
                  <div
                    key={answer.id}
                    // onClick={this.onClickAnswer.bind(this, answer)}
                    className="bg-white p-3 px-lg-5 py-lg-4 shadow-sm rounded-lg mt-3"
                  >
                    {answer.average >= 92.5 && (
                      <span
                        className="badge "
                        style={{ backgroundColor: "#0000FF" }}
                      >
                        PASS
                      </span>
                    )}
                    {answer.average < 92.5 && (
                      <span className="badge bg-danger">FAIL</span>
                    )}
                    <h5 className="mt-2">{answer.name}</h5>
                    <p className="fs-sm">
                      <span className="badge bg-primary me-1">
                        {answer.createdAt}
                      </span>
                    </p>
                    <div className="d-flex overflow-auto">
                      <div className="text-center">
                        <h2>
                          <span className="badge bg-warning m-0">{rank}</span>
                        </h2>
                        <p className="fs-xs fw-bold m-0">RANK</p>
                      </div>
                      <div className="text-center ms-2">
                        <div className="container">
                          <div className="row">
                            <div className="d-flex">
                              {Object.keys(answer.form).map((f) => {
                                const score = answer.form[f].score;
                                const total = answer.form[f].total;
                                const percentage = (score / total) * 100;

                                return (
                                  <>
                                    <div key={f} className="mx-2">
                                      <h4>
                                        {percentage.toFixed(2) >= 92.5 ? (
                                          <span
                                            className="badge m-0"
                                            style={{
                                              backgroundColor: "#0000FF",
                                            }}
                                          >
                                            {percentage.toFixed(2)}%
                                          </span>
                                        ) : (
                                          <span className="badge bg-danger m-0">
                                            {percentage.toFixed(2)}%
                                          </span>
                                        )}
                                      </h4>
                                      <p className="fs-xs m-0">{f}</p>
                                    </div>
                                  </>
                                );
                              })}
                              <div className="mx-2">
                                <h4 className="">
                                  <span
                                    className="badge m-0"
                                    style={{
                                      backgroundColor: "#0000FF",
                                    }}
                                  >
                                    {this.computeProductAverage(
                                      answer.form
                                    ).toFixed(2)}
                                    %
                                  </span>
                                </h4>
                                <p className="fs-xs m-0">Product Average</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {form["Store Audit"] || form["Follow up Audit"] ? (
                        <div className="text-center ms-2">
                          {/* <h4>
                          <span className="badge bg-primary m-0">
                            {FinalAverage?.toFixed(2)}%
                          </span>
                        </h4> */}

                          <h4>
                            {FinalAverage.toFixed(2) >= 92.5 && (
                              <span
                                className="badge  m-0"
                                style={{ backgroundColor: "#0000FF" }}
                              >
                                {FinalAverage.toFixed(2)}%
                              </span>
                            )}
                            {FinalAverage.toFixed(2) < 92.5 && (
                              <span
                                className="badge  m-0"
                                style={{ backgroundColor: "#FF0000" }}
                              >
                                {FinalAverage.toFixed(2)}%
                              </span>
                            )}
                          </h4>
                          <p className="fs-xs m-0">Total</p>
                          <p className="fs-xs m-0">Average</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(StoreRankPage);
