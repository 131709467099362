import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import {
    findObjectUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import TrainingExamViewPresenter from "./TrainingExamViewPresenter";
import { Link } from "react-router-dom";
import InputFactory from "../../components/InputFactory";

class TrainingExamView extends BasePage {
    constructor(props) {
        super(props);
        this.presenter = new TrainingExamViewPresenter(
            this,
            findObjectUseCase(),
            saveObjectUseCase(),
            updateObjectUseCase()
        );
        this.state = {
            learn: null,
            progress: false,
        };
        this.overflow = createRef();
    }

    componentDidMount() {
        this.presenter.componentDidMount();
    }

    getTrainingId() {
        const params = this.getParams();
        return params.id;
    }

    setTraining(learn) {
        this.setState({ learn });
    }

    getTraining() {
        return this.state.learn || this.getArgument();
    }

    onClickBack() {
        this.presenter.onClickBack();
    }

    render() {
        const learn = this.state.learn;
        return (
            <>
                <NavBar className='shadow-sm' />
                <div className='nav-scroller'>
                    <nav className='nav nav-tabs'>
                        <Link to='/training/examboard'>
                            <button
                                className='text-nowrap nav-link active'
                                href='#'
                            >
                                <i className='bi bi-arrow-left-circle'></i> Back
                            </button>
                        </Link>
                    </nav>
                </div>
                <div className='container'>
                    <div className='py-2 px-lg-1 py-lg-2'>
                        <div
                            id='vid-content'
                            className='container-fluid d-flex flex-column justify-content-center'
                        >
                            <div className='row justify-content-center'>
                                {learn && (
                                    <div className='col-sm-7'>

                                        <div className='container m-0 p-0 pt-4'>
                                            <h5 className='ms-2 me-2'>
                                                <strong>{learn.examName}</strong>
                                            </h5>
                                            <p className='p-1 mt-1'>{learn.examDesc}</p>
                                        </div>
                                        <hr></hr>
                                        {learn.categories.map((cat) => (
                                            <div className='container m-0 p-0 pt-4'>

                                                <h5 className='ms-2 me-2'>
                                                    <strong>{cat.examCategory}</strong>
                                                </h5>

                                                {cat.questions.map((question, c) => (
                                                    <div className='container m-0 p-0 pt-41'>

                                                        <h6 className='ms-3 me-3'>
                                                            <strong>{(c + 1) + ". " + question.question}</strong>
                                                        </h6>

                                                        {question.isIdentification && (
                                                            <InputFactory
                                                                field="field"
                                                                type="String"
                                                                label="answer"
                                                            />
                                                        )}

                                                        {!question.isIdentification && (question.answers.map((answer) => (
                                                            <div className='container m-0 p-0 pt-41'>

                                                                <h6 className='ms-2 me-2'>
                                                                    <InputFactory
                                                                        field={answer.answer}
                                                                        object={answer}
                                                                        type="Boolean"
                                                                        label="answer"
                                                                        value={answer.isCorrect}
                                                                    />
                                                                </h6>
                                                            </div>
                                                        )))}

                                                    </div>
                                                ))}
                                                <hr></hr>
                                            </div>
                                        ))}

                                    </div>
                                )}

                                {/* submit button here */}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withContext(TrainingExamView);
