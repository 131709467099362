class TrainingVideoEditPresenter {
  constructor(
    view,
    findObjectUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
    saveFileUseCase
  ) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.saveFileUseCase = saveFileUseCase;
    this.learn = null;
  }

  componentDidMount() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getTraining())
      .then(() => this.view.hideProgress())
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  getTraining() {
    this.learn = this.view.getTraining();
    this.view.setTraining(this.learn);
    if (!this.learn) {
      const id = this.view.getTrainingId();
      const query = {
        where: { id: id },
        include: ["all"],
      };
      return this.findObjectUseCase
        .execute("training_videos", query)
        .then(([learn]) => {
          this.learn = learn;
          this.view.setTraining(this.learn);
        });
    }
  }

  submit() {
    const object = this.view.getTraining();
    const user = this.view.getCurrentUser();

    // const department = 'role:' + object.departments.name;
    const admin = "role:" + "admin";

    object.acl = {
      read: [user.id, admin],
      write: [user.id, admin],
    };

    this.view.showProgress();
    this.updateObjectUseCase
      .execute("training_videos", object)
      .then(() => {
        this.view.hideProgress();
        this.view.navigateTo("/training/video-links");
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onClickBack() {
    this.view.navigateBack();
  }
}

export default TrainingVideoEditPresenter;
