import BasePage from "../../base/BasePage";
import TrainingExamCreatePresenter from "./TrainingExamCreatePresenter";
import InputFactory from "../../components/InputFactory";
import {saveObjectUseCase, findObjectUseCase, updateObjectUseCase} from '../../usecases/object';
import NavBar from "../../components/NavBar";
import camelToTitleCase from "../../camelToTitleCase";
import withRouter from "../../withRouter";
import Progress from "../../components/Progress";

class TrainingExamCreate extends BasePage {
  constructor(props) {
    super(props);
    this.state = {object: {}};
    this.presenter = new TrainingExamCreatePresenter(this, saveObjectUseCase(), findObjectUseCase(), updateObjectUseCase());
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "exams";
  }

  getObjectId() {
    return this.props.params.id;
  }

  formSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  getObject() {
    return this.state.object;
  }


  backCLick() {
    this.presenter.backClick();
  }


  setObject(object) {
    object.categories = object.categories.sort((a, b) => a.name < b.name ? -1 : 1);
    this.setState({object});
  }

  render() {
    const object = this.state.object;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    if(this.state.progress)return <Progress/>;

    return (
        <>
          <NavBar className="shadow-sm"/>
          <div className="container p-3 px-lg-5 py-lg-4 overflow-auto">
            <h2 className="text-capitalize">
              Add New {this.getCollectionName()}
            </h2>
            <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
              <form onSubmit={this.formSubmit.bind(this)}>
                <div className="row g-3 mb-3">
                  <div>
                    <p className="small fw-bold mb-0 ms-1">Form For Creating Exam</p>
                    <hr className="dropdown-divider"/>
                  </div>
                  <div className="col-4">
                    <label
                        className="form-label fs-sm">Exam Title</label>
                    <InputFactory
                        className="fs-sm"
                        field="examName"
                        type="String"
                        object={object}/>
                  </div>
                  <div className="col-4">
                    <label
                        className="form-label fs-sm">Description</label>
                    <InputFactory
                        className="fs-sm"
                        field="examDesc"
                        type="String"
                        object={object}/>
                  </div>
                  <div>
                    <p className="small fw-bold mb-0 ms-1">Form Categories</p>
                    <hr className="dropdown-divider"/>
                  </div>
                  <InputFactory
                      className="fs-sm"
                      field="categories"
                      type="Relation"
                      _type="Related"
                      target="exams_categories"
                      object={object}/>
                  <div className="col-12">
                    <button type="submit" className="btn btn-primary fs-sm me-3">
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                    <button type="button" className="btn btn-light fs-sm"
                            onClick={this.backCLick.bind(this)}>GO BACK
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
    )
  }
}

export default withRouter(TrainingExamCreate);