import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";
import withContext from "../../withContext";
import TrainingExamPresenter from "./TrainingExamPresenter";
import { InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";
import { Link } from "react-router-dom";
import { deleteObjectUseCase, findObjectUseCase } from "../../usecases/object";
import DateRange from "../../components/DateRange";

class TrainingExamPage extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingExamPresenter(this, findObjectUseCase(), deleteObjectUseCase());
    this.state = {
      exams: [],
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setExam(exams) {
    this.setState({
      exams,
    });
  }

  onClickExam(exams) {
    this.presenter.onClickExam(exams);
  }

  getTrainId() {
    const params = this.getParams();
    return params.id;
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    // this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  onDeleteTrain(event, exams) {
    event.stopPropagation();
    this.presenter.onDeleteTrain(exams);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const exams = this.state.exams;
    const roles = this.getCurrentRoles();
    console.log(exams)

    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Exam.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <form className="mt-3">
              <div className="d-flex justify-content-end">
                <DateRange
                  // onChange={this.onChangeDate.bind(this)}
                  field="createdAt"
                />
              </div>
            </form>
            <Card className="mb-3 mt-3">
              <h6 className="border-bottom pb-2 mb-0">
                <i className="bi bi-person-video3"></i> Exams
              </h6>
              <Scroll
                hasMore={exams.length < count}
                loadMore={this.loadMore.bind(this)}
              >
                {exams.map((exam, id) => (
                  <div
                    key={id}
                    onClick={this.onClickExam.bind(this, exam)}
                    className="mb-1 cursor-pointer"
                  >
                    <div className="d-flex text-muted pt-3">
                      <small className="d-block border-end border-1">
                        {dateFormat(exam.createdAt)}
                      </small>
                      <div className="mx-3 pb-3 mb-0 small lh-sm w-100">
                        <strong className="d-block">
                          {exam.examName}
                        </strong>
                        <small className="d-block">
                          {exam.examDesc}
                        </small>
                      </div>
                      <div
                        className={roles?.admin ? "" : "d-none"}
                        onClick={(event) => this.onDeleteTrain(event, exam)}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                ))}
                {progress && <Progress className="fs-sm">Loading ...</Progress>}
                {!progress && exams.length === 0 && (
                  <NotFoundPage message="No results found" />
                )}
              </Scroll>
            </Card>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0">
          <Link to="/training/examcreate">
            <div
              className={roles?.admin ? "" : "d-none"}
            >
              <button className="btn btn-primary rounded-circle m-4 shadow">
                <i className="bi bi-plus fs-4"></i>
              </button>
            </div>
          </Link>
        </div>
      </>
    );
  }
}

export default withContext(TrainingExamPage);
