import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React, { createRef } from "react";
import Card from "../../components/Card";
import withContext from "../../withContext";
import TrainingAdminPresenter from "./TrainingAdminPresenter";

import { Modal, InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";
import { Link } from "react-router-dom";
import {
  deleteObjectUseCase,
  findObjectUseCase,
  saveObjectUseCase,
} from "../../usecases/object";
import DateRange from "../../components/DateRange";
import Button from "../../components2/Button";
import Spinner from "../../components2/Spinner";
import { convertToAmPmFormat } from "../../getDateAndTimeFromISO";

class TrainingAdminPage extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingAdminPresenter(
      this,
      findObjectUseCase(),
      deleteObjectUseCase(),
      saveObjectUseCase()
    );
    this.state = {
      selectedId: "",
      generatedLink: "",
      timeExpiration: "",
      isLinkCopied: false,
      copiedLink: "",
      activeLinks: [],
      expiredLinks: [],
      learns: [],
      linkModal: false,
      clearProgress: false,
      progress: false,
      count: 0,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setTrain(learns) {
    this.setState({
      learns,
    });
  }

  setActiveLinks(activeLinks) {
    this.setState({
      activeLinks,
    });
  }

  onClickTrain(learn) {
    this.presenter.onClickTrain(learn);
  }

  getTrainId() {
    const params = this.getParams();
    return params.id;
  }

  getMinDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    return date + "T00:00";
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    // this.presenter.loadMore();
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  onDeleteTrain(event, learn) {
    event.stopPropagation();
    this.presenter.onDeleteTrain(learn);
  }

  onShareLink(event, id) {
    event.stopPropagation();
    this.presenter.getActiveLinks(id);
    this.setState({ linkModal: true, selectedId: id });
  }

  onDeleteLink(link) {
    this.presenter.onDeleteLink(link);
  }

  onCancelShareLink() {
    this.setState({
      selectedId: "",
      linkModal: false,
      generatedLink: "",
      timeExpiration: "",
      isLinkCopied: false,
      activeLinks: [],
    });
  }

  onTimeExpirationInput(e) {
    this.setState({ timeExpiration: e.target.value });
  }

  onClearExpiredLinks() {
    this.presenter.clearExpiredLinks();
  }

  validateTimeExpiration(dateInput) {
    const currentTime = new Date();
    const selectedTime = new Date(dateInput);
    const timeDifference = selectedTime - currentTime;

    const hoursDifference = timeDifference / (1000 * 60 * 60);

    if (hoursDifference < 2) {
      this.showError(
        "Time is invalid. It must be greater than 2 hours from the current time."
      );
      return false;
    }
    return true;
  }

  onCopyLink(generatedLink) {
    if (this.state.generatedLink === "") return;

    navigator.clipboard.writeText(generatedLink);
    this.setState({ isLinkCopied: true });
    setTimeout(() => {
      this.setState({ isLinkCopied: false });
    }, 2500);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const learns = this.state.learns;
    const roles = this.getCurrentRoles();
    const linkModal = this.state.linkModal;
    const generatedLink = this.state.generatedLink;
    const isLinkCopied = this.state.isLinkCopied;
    const timeExpiration = this.state.timeExpiration;
    const activeLinks = this.state.activeLinks;
    const minDate = this.getMinDate();

    console.log(timeExpiration && new Date(timeExpiration).toISOString());

    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Training.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <form className="mt-3">
              <div className="d-flex justify-content-between">
                <div
                  role="button"
                  className="text-danger"
                  onClick={() => this.onClearExpiredLinks()}
                >
                  Clear Expired Links{" "}
                  {this.state.clearProgress ? (
                    <Spinner className="text-danger ms-2" size="sm" />
                  ) : (
                    <>({this.state.expiredLinks.length})</>
                  )}
                </div>
                <div className="d-flex">
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
              </div>
            </form>
            <Card className="mb-3 mt-3">
              <h6 className="border-bottom pb-2 mb-0">
                <i className="bi bi-person-video3"></i> Training
              </h6>
              <Scroll
                hasMore={learns.length < count}
                loadMore={this.loadMore.bind(this)}
              >
                {learns.map((learn, id) => (
                  <div
                    key={id}
                    onClick={this.onClickTrain.bind(this, learn)}
                    className="mb-1 cursor-pointer"
                  >
                    <div className="d-flex text-muted pt-3">
                      <small className="d-block border-end border-1">
                        {dateFormat(learn.createdAt)}
                      </small>
                      <div className="mx-3 pb-3 mb-0 small lh-sm w-100">
                        <strong className="d-block">
                          {learn.title}{" "}
                          {/* <span className="text-danger">
                            {this.checkExpiration(learn.date_exp)}
                          </span> */}
                        </strong>
                        <small className="d-block">{learn.description}</small>
                      </div>
                      <div
                        className="mx-1"
                        onClick={(event) => this.onShareLink(event, learn.id)}
                      >
                        <i class="bi bi-share"></i>
                      </div>
                      <div
                        className={roles?.admin ? "" : "d-none"}
                        onClick={(event) => this.onDeleteTrain(event, learn)}
                      >
                        <i className="bi bi-trash"></i>
                      </div>
                    </div>
                  </div>
                ))}
                {progress && <Progress className="fs-sm">Loading ...</Progress>}
                {!progress && learns.length === 0 && (
                  <NotFoundPage message="No results found" />
                )}
              </Scroll>
            </Card>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0">
          <Link to="/training/create">
            <div className={roles?.admin ? "" : "d-none"}>
              <button className="btn btn-primary rounded-circle m-4 shadow">
                <i className="bi bi-plus fs-4"></i>
              </button>
            </div>
          </Link>
        </div>
        <Modal isOpen={linkModal}>
          <div className="m-4">
            <label className="form-label">Expiration Date</label>
            <input
              type="datetime-local"
              className="form-control mb-2"
              min={minDate}
              onInput={(e) => this.onTimeExpirationInput(e)}
            />
            <div className="d-flex gap-2 mb-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    generatedLink &&
                    `${window.location.origin}/watch/${generatedLink}`
                  }
                  readOnly
                />
                <div class="input-group-append">
                  <span
                    className="input-group-text"
                    role="button"
                    onClick={() => {
                      this.onCopyLink(
                        `${window.location.origin}/watch/${generatedLink}`
                      );
                    }}
                  >
                    {isLinkCopied ? (
                      <i className="bi bi-check"></i>
                    ) : (
                      <i className="bi bi-clipboard"></i>
                    )}
                  </span>
                </div>
              </div>
            </div>
            <Button
              className="btn btn-primary me-3"
              disabled={timeExpiration === ""}
              progress={this.state.progress}
              onClick={() =>
                this.presenter.onGenerateLink(
                  this.state.selectedId,
                  timeExpiration
                )
              }
            >
              Generate
            </Button>
            <button
              className="btn btn-secondary"
              onClick={() => this.onCancelShareLink()}
            >
              Cancel
            </button>
            <div className="mt-2">
              <div>Active Links:</div>
              {activeLinks.map((link, index) => (
                <div
                  className="d-flex align-items-center gap-2 mb-2"
                  key={index}
                >
                  <div>
                    {/* {dateFormat(link.date_exp)}{" "} */}
                    {new Date(link.date_exp).toISOString().slice(0, 10)} <br />
                    {convertToAmPmFormat(link.date_exp)}
                  </div>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={`${link.id}`}
                      readOnly
                    />
                    <div class="input-group-append d-flex">
                      <span
                        className="input-group-text"
                        role="button"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${window.location.origin}/watch/${link.id}`
                          );
                          this.setState({
                            copiedLink: link.id,
                            isLinkCopied: false,
                          });
                        }}
                      >
                        {this.state.copiedLink === link.id ? (
                          <i className="bi bi-check"></i>
                        ) : (
                          <i className="bi bi-clipboard"></i>
                        )}
                      </span>
                      <span
                        className="input-group-text"
                        role="button"
                        onClick={() => this.onDeleteLink(link)}
                      >
                        <i className="bi bi-trash"></i>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withContext(TrainingAdminPage);
