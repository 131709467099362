import answersScore from "../../answersScore";
import scrollToFirstDangerElement from "./Scroll";

class FormAnswerPresenter {
  constructor(
    view,
    findObjectUseCase,
    saveObjectUseCase,
    updateObjectUseCase,
    deleteObjectUseCase
  ) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  componentDidMount() {
    this.getForm();
    this.getAnswer();
    this.getBrands();
  }

  getAnswer() {
    const collection = "form_answers";
    const answerId = this.view.getAnswerId();
    const query = {
      where: { id: answerId },
      include: [
        "store",
        "form.categories.questions",
        "answers",
        "answers.question",
        "uploads",
        "brand",
      ],
    };

    if (answerId) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute(collection, query)
        .then(([object]) => {
          if (object.answers) {
            const result = answersScore(object.answers);
            object.average = (result.score / result.total) * 100;
          }

          this.view.hideProgress();
          this.view.setForm(object.form);
          this.view.setBrand(object.brand?.id);
          this.view.setAnswer(object);
          this.view.getFile(object);
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  getBrands() {
    const collection = "brands";
    this.findObjectUseCase
      .execute(collection)
      .then((object) => {
        this.view.setBrands(object);
      })
      .catch((error) => {});
  }

  getForm() {
    const collection = "forms";
    const formId = this.view.getFormId();
    const answerId = this.view.getAnswerId();
    const query = {
      where: { id: formId },
      include: ["categories.questions"],
    };
    if (formId && answerId === undefined) {
      this.view.showProgress();
      this.findObjectUseCase
        .execute(collection, query)
        .then(([object]) => {
          this.view.hideProgress();
          this.object = object;

          const answer = { answers: [] };
          answer.form = { id: object.id };
          this.view.setAnswer(answer);
          this.view.setForm(Object.assign({}, object));
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.showError(error);
        });
    }
  }

  // deleteSelected() {
  //   const selected = this.view.getSelected();
  //   const collection = this.view.getCollectionName();
  //   this.view.showDialog({title: 'Delete Data?', message: 'Are you sure you want to delete?'})
  //       .then(() => Promise.all(selected.map(o => this.deleteObjectUseCase.execute(collection, o.id))))
  //       .then(() => {
  //           this.objects = this.objects.filter(o => !selected.includes(o));
  //           this.view.setObjects(this.objects);
  //       })
  //       .catch((error) => {
  //           this.view.hideProgress();
  //           this.view.showError(error);
  //       });
  // }

  submit() {
    const collection = "form_answers";
    const object = this.view.getAnswer();

    const validate = this.view.getValidate();

    this.view.showProgress();

    const fieldsToValidate = [
      { name: "concerns" },
      { name: "cashCount" },
      { name: "name1" },
      { name: "position1" },
      { name: "name2" },
      { name: "position2" },
      // { name: "signature1" },
      // { name: "signature2" },
    ];

    let isValidated = true;

    if (object) {
      if (object["answers"]) {
        object["answers"].map((ans) => {
          if (!ans.answer) {
            isValidated = false;
            this.view.setValidate(true);
            this.view.setSubmit(true);
          } else if (ans.answer === "NO" && !ans.remarks) {
            isValidated = false;
            this.view.setValidate(true);
            this.view.setSubmit(true);
          }
        });
      }
      for (const field of fieldsToValidate) {
        if (!object[field.name] || object[field.name] === "") {
          isValidated = false;
          break;
        }
      }
    }

    if (!isValidated) {
      this.view.setValidate(true);
      this.view.setSubmit(true);
      return;
    }

    this.view.showProgressDialog();

    if (object.id) {
      this.updateObjectUseCase
        .execute(collection, object)
        .then(() => {
          this.view.hideProgressDialog();
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.hideProgressDialog();
          this.view.showError(error);
        });
    } else {
      const roles = this.view.getCurrentRoles();
      const aclRoles = roles.map((r) => `role:${r.name}`);
      const user = this.view.getCurrentUser();

      const acl = {
        read: ["*", user.id, ...aclRoles],
        write: [user.id, "role:admin"],
      };
      object.acl = acl;

      for (const answer of object.answers) {
        answer.acl = acl;
      }

      this.saveObjectUseCase
        .execute(collection, object)
        .then(() => {
          this.view.hideProgressDialog();
          this.view.hideProgress();
          this.view.navigateBack();
        })
        .catch((error) => {
          this.view.hideProgress();
          this.view.hideProgressDialog();
          this.view.showError(error);
        });
    }
  }

  onChangeStore(value) {}

  onChangeBrand(value) {
    const brand = this.view.getBrand();
    const object = this.view.getAnswer();

    if (object.store && brand !== value.id) {
      delete object.store;
    }

    this.view.setBrand(value.id);
  }

  backClick() {
    // @todo check if object is un-save show discard
    this.view.navigateBack();
  }

  onClickExport(props) {
    this.view.exportPDF(props);
  }
}

export default FormAnswerPresenter;
