import React, { useState, useEffect, useRef } from "react";
import InputAnswer from "../InputAnswer";
import InputFactory from "../InputFactory";
import InputFile2 from "../InputFile2";
import InputImageAnswer from "../InputImageAnswer";
import InputString from "../InputString";
import InputText from "../InputText";
import InputSignature from "../InputSignature";
import SaveFileUseCase from "../../usecases/file/SaveFileUseCase";
import { saveFileUseCase } from "../../usecases/file";

const Question = ({
  categories,
  answer,
  submit,
  setSubmit,
  validate,
  file,
  setValidate,
}) => {
  let index = -1;

  const [questionRef, setQuestionRef] = useState([]);

  const [concerns, setConcerns] = useState(answer["concerns"]);
  const [cashCount, setCashCount] = useState(answer["cashCount"]);
  const [concerns2, setConcerns2] = useState(answer["concerns2"]);
  const [cashCount2, setCashCount2] = useState(answer["cashCount2"]);
  const [name1, setName1] = useState(answer["name1"]);
  const [position1, setPosition1] = useState(answer["position1"]);
  const [name2, setName2] = useState(answer["name2"]);
  const [position2, setPosition2] = useState(answer["position2"]);
  const [signature1, setSignature1] = useState(answer["signature1"]);
  const [signature2, setSignature2] = useState(answer["signature2"]);

  const cashCountRef = useRef(null);
  const concernsRef = useRef(null);
  const signature1Ref = useRef(null);
  const signature2Ref = useRef(null);
  const concerns2Ref = useRef(null);
  const cashCount2Ref = useRef(null);

  const [collapse, setCollapse] = useState(false);
  const [collapse2, setCollapse2] = useState(false);

  useEffect(() => {
    if (questionRef && questionRef[0] && submit) {
      questionRef[0].scrollIntoView({
        block: "center",
      });
      setQuestionRef([]);
      setSubmit(false);
    }
  }, [submit, questionRef]);

  useEffect(() => {
    if (submit) {
      if (!answer["cashCount"]) {
        addItemToQuestionRef(concernsRef.current);
      } else if (!answer["concerns"]) {
        addItemToQuestionRef(cashCountRef.current);
      } else if (!answer["concerns2"]) {
        addItemToQuestionRef(concerns2Ref.current);
      } else if (
        !answer["signature1"] ||
        !answer["name1"] ||
        !answer["position1"]
      ) {
        addItemToQuestionRef(signature1Ref.current);
      } else if (
        !answer["signature2"] ||
        !answer["name2"] ||
        !answer["position2"]
      ) {
        addItemToQuestionRef(signature2Ref.current);
      }
    }
  }, [submit]);

  const addItemToQuestionRef = (newItem) => {
    setQuestionRef((prevQuestionRef) => [...prevQuestionRef, newItem]);
  };

  const onCallapse = () => {
    return (
      <div>
        {answer?.uploads?.map((pic) => (
          <img
            src={pic}
            alt=""
            style={{ width: "50px", height: "50px" }}
            className="m-3 rounded-circle"
          />
        ))}
      </div>
    );
  };

  const onCallapse2 = () => {
    return (
      <div>
        {answer?.updateUploads?.map((pic) => (
          <img
            src={pic}
            alt=""
            style={{ width: "50px", height: "50px" }}
            className="m-3 rounded-circle"
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="mt-3">
        {categories.map((category, i) => {
          return (
            <React.Fragment key={category.id}>
              <h5>{category.name}</h5>
              <ol>
                {category.questions.map((question, j) => {
                  index++;
                  answer.answers[index] = answer.answers[index] || {};
                  answer.answers[index].question = { id: question.id };
                  return (
                    <li key={question.id} className="mt-2">
                      <InputAnswer
                        object={answer.answers[index]}
                        question={question.question}
                        submit={submit}
                        validate={validate}
                        questionRef={questionRef}
                        setQuestionRef={setQuestionRef}
                      />
                    </li>
                  );
                })}
              </ol>
            </React.Fragment>
          );
        })}
      </div>

      <div ref={cashCountRef} className="mt-1">
        <p>Cash Count</p>
        <p className={validate && !cashCount ? "text-danger small" : "d-none"}>
          this field is required
        </p>
        <InputText
          className="mt-1"
          type="Text"
          field="cashCount"
          object={answer}
          placeHolder=""
          shouldValidate={validate}
          rows={3}
          setState={setCashCount}
          // required
        />
      </div>

      <div className="mb-5 mt-3" ref={concernsRef}>
        <p>Concerns and Recommendations</p>
        <p className={validate && !concerns ? "text-danger small" : "d-none"}>
          this field is required
        </p>
        <InputText
          className="mt-1"
          type="Text"
          field="concerns"
          object={answer}
          placeHolder=""
          shouldValidate={validate}
          row={3}
          setState={setConcerns}
          // required
        />
      </div>

      <div className="mt-3">
        <label className="form-label" for="customFile">
          <strong>
            Deviations
            <span className="text-danger"></span>
          </strong>
        </label>
      </div>
      <InputFile2 className="form-control" field="uploads" object={answer} />

      {/* new add */}
      <div ref={concerns2Ref}>
        <strong>
          <p className="mt-5">Updates</p>
        </strong>
        <p className={validate && !concerns2 ? "text-danger small" : "d-none"}>
          this field is required
        </p>
        <InputText
          className="mt-1"
          type="Text"
          field="concerns2"
          object={answer}
          placeHolder=""
          shouldValidate={validate}
          row={3}
          setState={setConcerns2}
          // required
        />
      </div>

      <div className="mb-5 mt-3">
        <label className="form-label" for="customFile">
          Updates Attachment
          <span className="text-danger"></span>
        </label>

        <InputFile2
          className="form-control"
          field="updateUploads"
          object={answer}
        />
      </div>
      {/* <button
        className="btn btn-primary mb-4"
        type="button"
        onClick={() => onCallapse2(setCollapse2(!collapse2))}
      >
        View Image&nbsp;&nbsp;&nbsp;&nbsp; {collapse2 === true ? "⮟" : "⮞"}
      </button>
      {collapse2 === true ? (
        <div
          style={{
            backgroundColor: "white",
            borderRadius: 8,
          }}
        >
          {answer?.updateUploads?.map((pic) => (
            <img
              src={pic}
              alt=""
              style={{ width: "50px", height: "50px" }}
              className="m-3 rounded-circle"
            />
          ))}
        </div>
      ) : null} */}

      <div className="grid my-5 justify-content-between">
        <div className="row justify-content-between">
          <div ref={signature1Ref} className="mb-1 col-12 col-sm-6 col-lg-5">
            <p>Signature</p>
            <p
              className={
                validate && (!name1 || !position1)
                  ? "text-danger small"
                  : "d-none"
              }
            >
              this field is required
            </p>
            <div className="position-relative">
              <InputSignature
                className="mt-1"
                field="signature1"
                object={answer}
                save={saveFileUseCase()}
                setState={setSignature1}
              />
              <InputFactory
                className="mt-1"
                type="Signature"
                field="signature1"
                object={answer}
                placeHolder="Enter your name here"
                setState={setSignature1}
              />
            </div>
            <InputString
              className="mt-1"
              type="String"
              field="name1"
              object={answer}
              placeHolder="Enter your name here"
              shouldValidate={validate}
              setState={setName1}
              // required
            />

            <InputString
              className="mt-1"
              type="String"
              field="position1"
              object={answer}
              placeHolder="Enter your position here"
              shouldValidate={validate}
              setState={setPosition1}
              // required
            />
          </div>
          <div ref={signature2Ref} className="mb-1 col-12 col-sm-6 col-lg-5 ">
            <p>Signature</p>
            <p
              className={
                validate && (!name2 || !position2)
                  ? "text-danger small"
                  : "d-none"
              }
            >
              this field is required
            </p>
            <InputSignature
              className="mt-1"
              field="signature2"
              object={answer}
              save={saveFileUseCase()}
              setState={setSignature2}
            />
            <InputFactory
              className="mt-1"
              type="Signature"
              field="signature2"
              object={answer}
              placeHolder="Enter your name here"
              setState={setSignature2}
            />
            <InputString
              className="mt-1"
              type="String"
              field="name2"
              object={answer}
              placeHolder="Enter your name here"
              shouldValidate={validate}
              setState={setName2}
              // required
            />
            <InputString
              className="mt-1"
              type="String"
              field="position2"
              object={answer}
              placeHolder="Enter your position here"
              shouldValidate={validate}
              setState={setPosition2}
              // required
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Question;
